import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import HeroSection from "@/components/pages/home/_heroSection";
import {
  selecNftMarketPlaceSectionState,
  selectCategoriesData,
  selectExclusiveNftsData,
} from "@/config/helpers/createReduxSelectors";
import { ExclusiveData, getSettingCategories } from "@/store/actions";
import { homeMarketPlaceInfo } from "@/store/home/marketPlaceInfo/actions";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import "swiper/css";
import "swiper/css/pagination";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ExclusiveCollectionsCard = dynamic(
  () => import("./components/nftMarketPlace/ExclusiveCollectionsCard"),
  {
    ssr: false,
  }
);
const GuidesCard = dynamic(
  () => import("./components/nftMarketPlace/GuidesCard"),
  {
    ssr: false,
  }
);
const NftMarketplaceBanner = dynamic(
  () => import("./components/nftMarketPlace/NftMarketplaceBanner"),
  {
    ssr: false,
  }
);

const SandboxBannerSection = dynamic(() => import("./_SandboxBannerSection"), {
  ssr: false,
});
const PhotographersCard = dynamic(
  () => import("./components/nftMarketPlace/PhotographersCard"),
  {
    ssr: false,
  }
);
const PremierWeb3PlatformCard = dynamic(
  () => import("./components/nftMarketPlace/PremierWeb3PlatformCard"),
  {
    ssr: false,
  }
);
const TopUsersCard = dynamic(
  () => import("./components/nftMarketPlace/TopUsersCard"),
  {
    ssr: false,
  }
);

const VariousCategoriesCard = dynamic(
  () => import("./components/nftMarketPlace/VariousCategoriesCard"),
  {
    ssr: false,
  }
);

function NftMarketPlaceSection({ heroData }) {
  const dispatch = useDispatch();
  const [nftData, setNftData] = useState([]);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 399px)" });
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        setWidth(divRef.current.offsetWidth);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { loadingExclusiveNfts, nfts } = useSelector(selectExclusiveNftsData);

  useEffect(() => {
    if (isEmpty(nfts) && nfts === null) {
      setTimeout(() => {
        dispatch(ExclusiveData());
      }, 1200);
    }
  }, [dispatch, nfts]);

  useEffect(() => {
    if (!isEmpty(nfts)) {
      setNftData(nfts);
    }
  }, [nfts, nftData]);

  useEffect(() => {
    dispatch(homeMarketPlaceInfo());
    dispatch(getSettingCategories());
  }, []);

  const {
    errorMsgMarketPlaceInfo,
    loadingMarketPlaceInfo,
    errorMarketPlaceInfo,
    successMarketPlaceInfo,
    MarketPlaceInfo,
  } = useSelector(selecNftMarketPlaceSectionState);

  const {
    loadingSettingsCategories,
    successSettingsCategories,
    errorSettingsCategories,
    MessageSettingsCategories,
    SettingsCategories,
  } = useSelector(selectCategoriesData);

  return (
    <>
      <div className="center">
        <div className="home__nftMerketplace_head">
          <div className="home__nftMerketplace__title burst">
            <BurstPuckerImageTitles /> NFT Marketplace
          </div>

          <div className="home__nftMerketplace__btnExploreforPC">
            <Link className="button-stroke" href="/marketplace">
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="center">
        <HeroSection heroData={heroData} differentButton={true}>
          <h1 className="hero__title h1">
            Photography
            <div>
              <span style={{ color: "#8e793e" }}> NFT</span> Platform
            </div>
          </h1>
          <div className="hero__stage">by photographers for photographers</div>

          <div className="hero__navigation">
            <Link className="button main__button" href="/marketplace">
              {isSmallScreen ? "Explore" : "Marketplace"}
            </Link>

            <Link className="button main__button" href="/tutorials">
              Guide
            </Link>
          </div>
        </HeroSection>
      </div>

      {/* <div className="center">
        <NftMarketplaceBanner divRef={divRef} />
      </div> */}

      <div className="sandbox">
        <SandboxBannerSection divRef={divRef} />
      </div>

      <div className="center">
        {isMobile ? (
          <div className="home__nftMerketplace__mobile2 home__nftMerketplace__mobile2-margin ">
            <Swiper
              modules={[Pagination, A11y]}
              spaceBetween={60}
              loop={false}
              pagination={{
                clickable: true,
              }}
              className="seed_carousel is-top-right-normal home__nftMerketplace__mobile2 
             "
            >
              {" "}
              <SwiperSlide className="home__nftMerketplace__slider ">
                {" "}
                <div
                  className="home__nftMerketplace__section1 home__nftMerketplace__mobile"
                  style={{
                    width: width - 24,
                    // marginRight: (width * 20) / 100,
                    marginLeft: "0px",
                  }}
                >
                  <PhotographersCard MarketPlaceInfo={MarketPlaceInfo} />
                  <ExclusiveCollectionsCard nftData={nftData} />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="home__nftMerketplace__section2 home__nftMerketplace__mobile "
                  style={{
                    width: width - 24,
                    marginRight: (width * 0) / 100,
                    marginLeft: (width * 0) / 100,
                  }}
                >
                  <GuidesCard MarketPlaceInfo={MarketPlaceInfo} />
                  <TopUsersCard MarketPlaceInfo={MarketPlaceInfo} />
                  <VariousCategoriesCard
                    SettingsCategories={SettingsCategories}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="home__nftMerketplace__section3 home__nftMerketplace__mobile "
                  style={{
                    width: width - 24,
                    marginRight: (width * 0) / 100,
                    marginLeft: (width * 0) / 100,
                  }}
                >
                  <PremierWeb3PlatformCard width={width} isMobile={isMobile} />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        ) : (
          <div className="home__nftMerketplace__section">
            <div className="home__nftMerketplace__section1 ">
              <PhotographersCard MarketPlaceInfo={MarketPlaceInfo} />
              <ExclusiveCollectionsCard nftData={nftData} />
            </div>
            <div className="home__nftMerketplace__section2 ">
              <div className="home__nftMerketplace__section2-2">
                <div className="home__nftMerketplace__section2-1">
                  <GuidesCard MarketPlaceInfo={MarketPlaceInfo} />
                  <TopUsersCard MarketPlaceInfo={MarketPlaceInfo} />
                </div>

                <VariousCategoriesCard
                  SettingsCategories={SettingsCategories}
                />
              </div>
            </div>
            <div className="home__nftMerketplace__section3 ">
              <PremierWeb3PlatformCard />
            </div>
          </div>
        )}
      </div>

      <div className="center">
        <div className="home__nftMerketplace">
          <Link
            className="button-stroke home__twitterCommunity__tablet"
            href="./marketplace"
          >
            Explore More
            <i className="icon-chevron_up"></i>
          </Link>
        </div>
      </div>
    </>
  );
}

export default NftMarketPlaceSection;
